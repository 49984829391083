<template>
  <div>

    <!-- Tabel -->
    <b-row>
      <b-col class="col-6">
        <b-table striped hover :items="listDPOrequest" :fields="dpoFields" show-empty :per-page="perPage" :current-page="currentPage">
          <template v-slot:cell(biodata)="row">
            <!-- <h3 style="text-align: center;" class="wanted-text" >-= {{ row.item.title }} =-</h3><hr/> -->
            <div class="d-flex" @click="showDPOInfo(row.item)">
              <!-- Foto -->
              
              <div class="wanted-overlay">
                  <img :src="require('@/assets/images/avatars/8.png')" v-if="row.item.imgurl == null && row.item.sex == 'm' || row.item.valid_img == false && row.item.sex == 'm'" style="width: 150px; height: 150px;">
                  <img :src="require('@/assets/images/avatars/11.png')" v-if="row.item.imgurl == null && row.item.sex == 'f'|| row.item.valid_img == false && row.item.sex == 'f'" style="width: 150px; height: 150px;">
                  <img :src="row.item.imgurl" style="min-width: 150px; height: 150px; width: 150px;" v-if="row.item.imgurl != null && row.item.valid_img == true">
                  <div class="overlay-text">WANTED</div>
              </div>
              <!-- Informasi biodata kedua -->
              <div class="col-3"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Nama KTP:</strong><br/><span style="color: yellow;">{{ row.item.fullname }}</span></p>
                <p><strong>Tanggal Lahir:</strong><br/> {{ row.item.dateofbirth }}</p>
              </div>
              <div class="col-12"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <!-- <p><strong>Pekerjaan :</strong><br/> {{ row.item.job }}</p> -->
                <p><strong>Periode DPO:</strong> <br/>{{ row.item.periode }}</p>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end" v-if="!Loading">
          <b-pagination v-model="currentPage" :total-rows="listDPOrequest.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
      <b-col class="col-6">
        <b-table striped hover :items="listDPkrequest" :fields="dpkFields" show-empty>
          <template v-slot:cell(biodata)="row">
            <!-- <h3 style="text-align: center;" class="wanted-text" >-= {{ row.item.title }} =-</h3><hr/> -->
            <div class="d-flex" @click="showDPKInfo(row.item)">
              <!-- Foto -->
              <div class="wanted-overlay">
                  <img :src="require('@/assets/images/avatars/car1.png')" v-if="row.item.imgurl == null" style="width: 150px; height: 150px;">
                  <img :src="row.item.imgurl" style="width: 150px; height: 150px;" v-if="row.item.imgurl != null">
                  <div class="overlay-text">WANTED</div>
              </div>
              <!-- Informasi biodata kedua -->
              <div class="col-3"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Plate:</strong> <br/><span style="color: yellow;">{{ row.item.plate }}</span></p>
                <p><strong>Model Name:</strong><br/> {{ row.item.modelname }}</p>
              </div>
              <div class="col-12"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Periode DPO:</strong> <br/>{{ row.item.periode }}</p>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-modal v-model="infoDPOModal" centered title="Informasi Status DPO" hide-footer size="lg">
      <loading v-if="LoadingDPO" />
      <div v-else class="row">
        <div class="col-md-8">
          <b-card>
            <img v-if="dpoData.image" :src="dpoData.image" style="width: 150px; border-radius: 0%;" class="profile-image" alt="Profile Image">
            <img v-else :src="require('@/assets/images/avatars/noimg.jpg')" style="width: 150px; border-radius: 0%;" class="profile-image" alt="Profile Image">
            <hr>
            <p>{{ dpoData.description }}</p>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card>
            <p>{{ dpoData.periode }}</p>
            <p>By : {{ dpoData.inserted_by_name }}</p>
            <b-button v-if="isValidUser" block variant="success" size="sm" @click="submitDPO(dpoData)"><strong> SUBMIT DPO </strong></b-button>
            <b-button v-if="isValidUser" block variant="danger" size="sm" @click="rejectDPO(dpoData)"><strong> REJECT </strong></b-button>
          </b-card>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="infoDPKModal" centered title="Informasi Status DPK" hide-footer size="lg">
      <loading v-if="LoadingDPO" />
      <div v-else class="row">
        <div class="col-md-8">
          <b-card>
            <img v-if="dpkData.image" :src="dpkData.image" style="width: 150px; border-radius: 0%;" class="profile-image" alt="Profile Image">
            <img v-else :src="require('@/assets/images/avatars/noimg.jpg')" style="width: 150px; border-radius: 0%;" class="profile-image" alt="Profile Image">
            <hr>
            <p>{{ dpkData.description }}</p>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card>
            <p>{{ dpkData.periode }}</p>
            <p>By : {{ dpkData.inserted_by_name }}</p>
            <b-button v-if="isValidUser" block variant="success" size="sm" @click="submitDPK(dpkData)"><strong> SUBMIT DPK </strong></b-button>
            <b-button v-if="isValidUser" block variant="danger" size="sm" @click="rejectDPK(dpkData)"><strong> REJECT </strong></b-button>
          </b-card>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios
  },
  data() {
    return {
      Loading: false,
      LoadingDetail: false,
      LoadingDPO: false,
      infoDPOModal: false,
      infoDPKModal: false,
      dpoFields: [
        {key:"biodata",text:"Data Warga"},
        // {key:"action",text:"Action"},
      ],
      dpkFields: [
        {key:"biodata",text:"Data Kendaraan"},
      ],
      modalShow: false,
      listDPOrequest: [],
      listDPkrequest: [],
      dpoData: [],
      dpkData: [],
      dpoForm:_interfaces.dpo,
      isValidUser: false,
      perPage: 5, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
    };
  },
  methods: {
    init(){
      this.getData();
      this.checkValidAccessButton(2);
    },
    getData() {
      axios.post("idp/dpo/request-list",{id:null}).then((response) => {
        this.listDPOrequest = response.data.dpo
        this.listDPkrequest = response.data.dpk
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    showDPOInfo(row){
      this.infoDPOModal = true
      axios.post("idp/user/dpo-info",{id:row.user_dpo_id}).then((response) => {
        this.dpoData = response.data.data
      });
    },
    showDPKInfo(row){
      this.infoDPKModal = true
      axios.post("idp/user/dpk-info",{id:row.user_dpk_id}).then((response) => {
        this.dpkData = response.data.data
      });
    },
    submitDPO(row){
      this.$swal({
        title: "Jadikan Sebagai DPO?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            axios.post("idp/user/approve-dpo",{id:row.id}).then((response) => {
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
                this.infoDPOModal = false;
                this.init();
              }else{
              }
            });
            this.dpoModal = false
        } else {
          this.dpoModal = false
        }
      });
    },
    submitDPK(row){
      this.$swal({
        title: "Jadikan Sebagai DPK?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            axios.post("idp/user/approve-dpk",{id:row.id}).then((response) => {
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
                this.infoDPKModal = false;
                this.init();
              }else{
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Update DPO Gagal",
                    icon: "EditIcon",
                    variant: "warning",
                    text: "Masih menjadi DPO",
                  },
                });
              }
            });
            this.dpoModal = false
        } else {
          this.dpoModal = false
        }
      });
    },
    rejectDPO(row){
      this.$swal({
        title: "Hapus Laporan DPO?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            axios.post("idp/user/reject-dpo",{id:row.id}).then((response) => {
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
                this.infoDPOModal = false;
                this.init();
              }else{
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Update DPO Gagal",
                    icon: "EditIcon",
                    variant: "warning",
                    text: "Masih menjadi DPO",
                  },
                });
              }
            });
            this.dpoModal = false
        } else {
          this.dpoModal = false
        }
      });
    },
    rejectDPK(row){
      this.$swal({
        title: "Hapus Laporan DPK?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            axios.post("idp/user/reject-dpk",{id:row.id}).then((response) => {
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
                this.infoDPKModal = false;
                this.init();
              }else{
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Update DPO Gagal",
                    icon: "EditIcon",
                    variant: "warning",
                    text: "Masih menjadi DPO",
                  },
                });
              }
            });
            this.dpoModal = false
        } else {
          this.dpoModal = false
        }
      });
    },
    checkValidAccessButton(id_config){
      axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
        this.isValidUser = response.data.valid
      })
    },
  },
  mounted(){
    this.init();
  }
}
</script>
<style scoped>
.wanted-text {
    font-family: 'Impact', sans-serif; /* Gunakan font yang memiliki tampilan tebal dan mencolok */
    font-size: 20px; /* Sesuaikan ukuran font sesuai kebutuhan */
    text-transform: uppercase; /* Ubah teks menjadi huruf besar */
    color: grey; /* Gunakan warna putih untuk teks */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Tambahkan shadow dengan ukuran dan warna yang diinginkan */
}

.wanted-overlay {
    position: relative;
    display: inline-block;
}

.wanted-image {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
}

@keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: white; /* Ubah warna teks menjadi merah */
    background-color: red;
    padding: 10px 20px;
    border-radius: 5px;
    animation: blinking 1s infinite alternate; /* Terapkan animasi blinking */
}
</style>

